import React from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

interface BalanceCheckProps {
    onCheckBalance: (e: React.MouseEvent) => void;
}

function BalanceCheck({ onCheckBalance }: BalanceCheckProps) {
    return (
        <Box display="flex" justifyContent="center" mt={2} width="100%">
            <Button
                variant="outlined"
                color="primary"
                onClick={onCheckBalance}
                sx={{ width: '200px' }}
            >
                Узнать баланс
            </Button>
        </Box>
    );
}

export default BalanceCheck;
