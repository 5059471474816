import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import {useSearchParams} from "react-router-dom";

interface BalanceInputProps {
    onInputChange: (contractNumber: string) => void;
}

function BalanceInput({ onInputChange }: BalanceInputProps) {
    const [contractNumber, setContractNumber] = useState("");
    const [searchParams] = useSearchParams();

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setContractNumber(event.target.value);
        onInputChange(event.target.value);
    };

    React.useEffect(() => {
        if (searchParams.has("contractNumber")) {
            setContractNumber(searchParams.get("contractNumber") || "");
        }

    }, [searchParams]);

    return (
        <Box display="flex" justifyContent="center" width="100%">
            <TextField
                label="Введите номер договора"
                variant="outlined"
                value={contractNumber}
                onChange={handleInputChange}
                fullWidth
            />
        </Box>
    );
}

export default BalanceInput;
