import React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import ListItemIcon from "@mui/material/ListItemIcon";

function SavedList() {
    const items = [
        { id: 1, label: "Домашний интернет", contractNumber: 1244534194 },
        { id: 2, label: "Интернет на работе", contractNumber: 5340003401 },
    ];

    return (
        <Box width="100%" mt={2}>
            <List>
                {items.map((item, index) => (
                    <React.Fragment key={item.id}>
                        <ListItem sx={{ height: "100%" }}>
                            <ListItemIcon sx={{ minWidth: "20px" }}>{index}</ListItemIcon>
                            <Divider sx={{ margin: "0 10px" }} orientation="vertical" flexItem  />
                            <ListItemText secondary={item.contractNumber} primary={item.label} />
                        </ListItem>
                        {index < items.length - 1 && <Divider />}
                    </React.Fragment>
                ))}
            </List>
        </Box>
    );
}

export default SavedList;
