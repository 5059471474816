import React, {useState} from 'react';
import { TextField, Box } from '@mui/material';
import {useSearchParams} from "react-router-dom";

const CONTRACT_NUMBER_FIELD = "contractNumber";

function PaymentInput() {
    const [searchParams, setSearchParams] = useSearchParams();
    const [contractNumber, setContractNumber] = useState("");

    const handleContractNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (searchParams.has(CONTRACT_NUMBER_FIELD)) {
            setSearchParams(params => {
                params.delete(CONTRACT_NUMBER_FIELD);
                return params;
            })
        }

        setContractNumber(event.target.value);
    }

    React.useEffect(() => {
        if (searchParams.has(CONTRACT_NUMBER_FIELD)) {
            setContractNumber(searchParams.get(CONTRACT_NUMBER_FIELD) || "");
        }
    }, [searchParams]);

    return (
        <Box display="flex" flexDirection="column" gap={2} width="100%">
            <TextField
                fullWidth
                value={contractNumber}
                onChange={handleContractNumberChange}
                variant="outlined"
                placeholder="Введите номер договора"
            />
            <TextField
                fullWidth
                variant="outlined"
                placeholder="Введите сумму"
            />
        </Box>
    );
}

export default PaymentInput;
