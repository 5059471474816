import React from 'react';
import {Box, Button} from '@mui/material';

function PaymentAction() {
    return (
        <Box display="flex" justifyContent="center" mt={2} width="100%">
            <Button
                variant="outlined"
                color="primary"
                sx={{ marginTop: '20px' }}
            >
                Пополнить баланс
            </Button>
        </Box>
    );
}

export default PaymentAction;
