import React from 'react';
import Header from './components/Header';
import BottomNavigationBar from './components/BottomNavigation';
import { BalanceProvider } from './context/BalanceContext';
import { QueryClient, QueryClientProvider } from 'react-query';
import Box from "@mui/material/Box";
import {Route, BrowserRouter as Router, Routes} from "react-router-dom";
import BalancePage from "./pages/BalancePage";
import PaymentPage from "./pages/PaymentPage";
import SavedPage from "./pages/SavedPage";

const queryClient = new QueryClient();

function App() {
    return (
        <QueryClientProvider client={queryClient}>
          <BalanceProvider>
              <Router>
                  <Box
                      display="flex"
                      flexDirection="column"
                      height="100vh"
                  >
                      <Box flex="0 0 auto">
                          <Header />
                      </Box>
                      <Box
                          display="flex"
                          flexDirection="column"
                          alignItems="center"
                          justifyContent="center"
                          flexGrow={1}
                          overflow="auto"
                      >
                          <Routes>
                              <Route path="/balance" element={<BalancePage />} />
                              <Route path="/payment" element={<PaymentPage />} />
                              <Route path="/saved" element={<SavedPage />} />
                              <Route path="/" element={<BalancePage />} />
                          </Routes>
                      </Box>
                      <Box flex="0 0 auto">
                          <BottomNavigationBar />
                      </Box>
                  </Box>
              </Router>
          </BalanceProvider>
        </QueryClientProvider>
    );
}

export default App;