import React, {createContext, useState, useContext, PropsWithChildren} from 'react';

interface IBalanceContext {
    balance: null | number,
    onChangeBalance: (value: number) => void
}

const BalanceContext = createContext<IBalanceContext>({
    balance: null,
    onChangeBalance: () => {}
});

export const useBalanceContext = () => {
    return useContext(BalanceContext);
};

interface BalanceProviderProps{

}

export const BalanceProvider = ({ children }: PropsWithChildren<BalanceProviderProps>) => {
    const [balance, setBalance] = useState<number|null>(null);

    const handleChangeBalance = (value: number) => {
        setBalance(value);
    }

    return (
        <BalanceContext.Provider value={{ balance, onChangeBalance: handleChangeBalance  }}>
            {children}
        </BalanceContext.Provider>
    );
};
