import React from 'react';
import SavedInput from '../components/SavedInput';
import SavedList from '../components/SavedList';
import { Box, Paper } from '@mui/material';

function SavedPage() {
    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="start"
            height="100%"
            width="100%"
            padding="10px"
            boxSizing="border-box"
        >
            <Paper
                elevation={3}
                sx={{
                    width: '100%', // Ensure it takes the full width of its parent container
                    maxWidth: '480px', // Set max width to ensure it doesn't get too wide on larger screens
                    padding: '20px',
                    boxSizing: 'border-box',
                    borderRadius: '12px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'start',
                    alignItems: 'center',
                    minHeight: '95%',
                }}
            >
                <Box width="100%">
                    <SavedInput />
                </Box>

                <Box width="100%" mt={2}>
                    <SavedList />
                </Box>
            </Paper>
        </Box>
    );
}

export default SavedPage;
