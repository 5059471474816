import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useBalanceContext } from '../context/BalanceContext';
import { UseQueryResult } from 'react-query';

interface BalanceDisplayProps {
    isLoading: boolean;
    error: unknown;
    data?: {
        balance: number
    }
}


function BalanceDisplay({ isLoading, error, data }: BalanceDisplayProps) {
    const { onChangeBalance } = useBalanceContext();

    if (isLoading) return <Typography>Загрузка баланса...</Typography>;
    if (error) return <Typography>Ошибка при загрузке баланса. <br/> Повторите позже</Typography>;

    if (data?.balance) {
        onChangeBalance(data.balance);
    }

    return (
        <Box mt={4} display="flex" justifyContent="center">
            <Typography variant="h5" color="primary">
                {data?.balance ? (
                    <div style={{ textAlign: "center"}}>
                        Текущий баланс: <br/>
                        {data?.balance} руб.
                    </div>
                    ) : 'Введите номер договора'}
            </Typography>
        </Box>
    );
}

export default BalanceDisplay;
