import React from 'react';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import Divider from "@mui/material/Divider";
import SavedInputModal from "./SavedInputModal";

function SavedInput() {
    return (
        <Box display="flex" width="100%">
            <TextField
                label="Введите номер договора"
                variant="outlined"
                fullWidth
                InputProps={{
                    endAdornment: (
                        <InputAdornment sx={{ height: "100%" }} position="end">
                            <Divider sx={{ margin: 0 }} variant="middle" orientation="vertical"  flexItem />
                            <SavedInputModal />
                        </InputAdornment>
                    ),
                }}
            />
        </Box>
    );
}

export default SavedInput;
