import React, { useState } from 'react';
import BalanceInput from '../components/BalanceInput';
import BalanceCheck from '../components/BalanceCheck';
import BalanceDisplay from '../components/BalanceDisplay';
import { Box } from '@mui/material';
import {useQuery, useQueryClient} from "react-query";
import {fetchBalance} from "../api/balanceApi";

function BalancePage() {
    const queryClient = useQueryClient();
    const [contractNumber, setContractNumber] = useState('');
    const balanceQuery = useQuery<{ balance: number }>({
        queryKey: ['balance/fetch', contractNumber],
        queryFn: () => fetchBalance(contractNumber),
        enabled: false
    });
    // const [balance, setBalance] = useState<number|null>(null);

    const handleCheckBalance = async (e: React.MouseEvent) => {
        e.preventDefault();
        if (contractNumber) {
            balanceQuery.refetch();
        }
    };

    const handleChangeContract = (number: string) => {
        setContractNumber(number);
    }

    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            height="100%"
            padding="20px"
            minWidth="300px"
        >
            <Box
                display="flex"
                justifyContent="center"
                alignItems="flex-start"
                flex="0 1 auto"
                mt={2}
            >
                <BalanceInput onInputChange={handleChangeContract} />
            </Box>

            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                flex="1"
            >
                <BalanceDisplay error={balanceQuery.error} isLoading={balanceQuery.isLoading} data={balanceQuery.data} />
            </Box>

            <Box
                display="flex"
                justifyContent="center"
                alignItems="flex-end"
                flex="0 1 20%"
                mb={2}
            >
                <BalanceCheck onCheckBalance={handleCheckBalance} />
            </Box>
        </Box>
    );
}

export default BalancePage;
