import axios from 'axios';

const apiClient = axios.create({
    baseURL: 'http://localhost:7080/api',
});

export const fetchBalance = async (contractNumber: string): Promise<{ balance: number }> => {
    const response = await apiClient.get("/account", {
        params: { contract_number: contractNumber }
    });

    return response.data;
};
