import React from 'react';
import {Box, Typography} from '@mui/material';

function PaymentDisplay() {
    return (
        <Box mt={4} display="flex" justifyContent="center">
            <Typography variant="h5" align="center" mt={4} color="primary">
                Текущий баланс: 123
            </Typography>
        </Box>
    );
}

export default PaymentDisplay;
