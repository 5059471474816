import React from 'react';
import { Box } from '@mui/material';
import PaymentInput from '../components/PaymentInput';
import PaymentDisplay from '../components/PaymentDisplay';
import PaymentAction from '../components/PaymentAction';

function PaymentPage() {
    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            height="100%"
            padding="20px"
        >
            <Box
                display="flex"
                justifyContent="center"
                alignItems="flex-start"
                flex="0 1 auto"
                mt={2}
            >
                <PaymentInput />
            </Box>

            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                flex="1"
            >
                <PaymentDisplay />
            </Box>

            <Box
                display="flex"
                justifyContent="center"
                alignItems="flex-end"
                flex="0 1 20%"
                mb={2}
            >
                <PaymentAction />
            </Box>
        </Box>
    );
}

export default PaymentPage;
