import React from 'react';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import BookmarksIcon from '@mui/icons-material/Bookmarks';
import AccountBalance from '@mui/icons-material/AccountBalance';
import AccountBalanceWallet from '@mui/icons-material/AccountBalanceWallet';
import {useLocation, useNavigate} from "react-router-dom";
import {styled} from "@mui/material";

const StyledBottomNavigation = styled(BottomNavigation)(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: '12px',
    margin: '0 10px 20px 10px',
    padding: '0 10px',
    backgroundColor: '#fff',
    height: '90px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
}));

const StyledBottomNavigationAction = styled(BottomNavigationAction)(({ theme }) => ({
    minWidth: 'auto',
    padding: '10px 0',
    margin: '0 5px',
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: '8px',
    backgroundColor: '#fff',
    transition: 'transform 0.3s ease, box-shadow 0.3s ease', // Smooth transition
    '&.Mui-selected': {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.background.paper,
        border: `1px solid ${theme.palette.primary.main}`,
        borderRadius: '12px',
        padding: '10px 12px',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
        transform: 'translateY(-30px)', // Move further up to create the "fall out" effect
    },
    '& .MuiSvgIcon-root': {
        fontSize: '1.5rem',
    },
}));

type Route = {
    [key: number]: string
}

const routes: Route = {
    1: "/saved",
    2: "/balance",
    3: "/payment"
}

const getRouteIndexByPath = (path: string) => {
    return Object.keys(routes).find(key => routes[Number(key)] === path);
}

function BottomNavigationBar() {
    const [value, setValue] = React.useState(2);
    const navigate = useNavigate();
    const location = useLocation();

    const handleChange = (newValue: number) => {
        setValue(newValue);
        navigate(routes[newValue]);
    };

    React.useEffect(() => {
        if (routes[value] !== location.pathname) {
            const routeIndex = getRouteIndexByPath(location.pathname);
            setValue(Number(routeIndex));
        }
    }, []);

    return (
        <StyledBottomNavigation
            value={value}
            onChange={(e, newValue) => handleChange(newValue)}
            showLabels
        >
            <div style={{ flex: 1 }} />
            <StyledBottomNavigationAction label="Saved" icon={<BookmarksIcon />} />
            <StyledBottomNavigationAction label="Balance" icon={<AccountBalance />} />
            <StyledBottomNavigationAction label="Payment" icon={<AccountBalanceWallet />} />
            <div style={{ flex: 1 }} />
        </StyledBottomNavigation>
    );
}

export default BottomNavigationBar;
